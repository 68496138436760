import { IPixelContents } from '@faire/web-api--source/indigofair/data/IPixelContents';

export namespace IPixelCustomDataParameters {
  /**
   * Return an instance of IPixelCustomDataParameters with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IPixelCustomDataParameters>
  ): IPixelCustomDataParameters =>
    ({
      content_ids: [],
      contents: [],
      ...(partial ?? {}),
    }) as IPixelCustomDataParameters;
}

export interface IPixelCustomDataParameters {
  brand_lead_token: string | undefined;
  brand_token: string | undefined;
  cart_value: number | undefined;
  content_ids: Array<string>;
  content_type: string | undefined;
  contents: Array<IPixelContents>;
  currency: string | undefined;
  num_items: number | undefined;
  retailer_token: string | undefined;
  value: number | undefined;
  store_type: string | undefined;
  annual_sales_range: string | undefined;
  years_in_business: string | undefined;
  twitter_click_id: string | undefined;
}
