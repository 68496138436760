export {
  TypographyStyles,
  Truncate,
  MaxLines,
  Align,
  objectToCssString,
} from "./Styles";
export { Span, Typography } from "./Typography";
export type {
  TypographyVariant,
  TypographyVariants,
  TypographyTags,
  TypographyTheme,
  TypographyProps,
} from "./Types";
export { fontRamp } from "./Themes";
