export type LocalApp =
  | "retailer"
  | "brand"
  | "admin"
  | "developers"
  | "d2c"
  | "widget"
  | "blog"
  | "help-center"
  | "visitor"
  | "releases"
  | "experiments"
  | "goose"
  | "full-page-widget";

type DefinedEnvironment =
  | "production"
  | "prerelease"
  | "staging"
  | "staging2"
  | "local";
type UndefinedEnvironment = "sandbox" | "build";
export type Environment = DefinedEnvironment | UndefinedEnvironment;

export const LOCAL_PORT: { [K in LocalApp]: number } = {
  retailer: 3000,
  brand: 3001,
  admin: 3003,
  developers: 3004,
  d2c: 3005,
  widget: 3006,
  blog: 3007,
  "help-center": 3008,
  visitor: 3009,
  releases: 3010,
  experiments: 3011,
  goose: 3012,
  // web: 3013, // web doesn't have a dev server but it has a storybook on 4013
  "full-page-widget": 3014,
} as const;
export type LocalAppPort = (typeof LOCAL_PORT)[keyof typeof LOCAL_PORT];

const SUBDOMAINS: Partial<Record<LocalAppPort, string>> = {
  [LOCAL_PORT.developers]: "developers",
  [LOCAL_PORT.blog]: "blog",
} as const;

const isFriendlyName = (spec?: LocalApp | LocalAppPort): spec is LocalApp => {
  return !!spec && spec in LOCAL_PORT;
};

export function faireBaseUrlMatcher(
  env: DefinedEnvironment,
  nameOrLocalhostPort?: LocalApp | LocalAppPort
): string;
export function faireBaseUrlMatcher(
  env: Environment,
  nameOrLocalhostPort?: LocalApp | LocalAppPort
): string | undefined;
export function faireBaseUrlMatcher(
  env: Environment,
  nameOrLocalhostPort?: LocalApp | LocalAppPort
): string | undefined {
  const port: LocalAppPort | undefined = isFriendlyName(nameOrLocalhostPort)
    ? LOCAL_PORT[nameOrLocalhostPort]
    : nameOrLocalhostPort;
  const subdomain = (!!port && SUBDOMAINS[port]) || "www";
  if (env === "production") {
    return `https://${subdomain}.faire.com`;
  } else if (env === "staging") {
    return `https://${subdomain}.faire-stage.com`;
  } else if (env === "staging2") {
    return `https://${subdomain}.staging.faire.dev`;
  } else if (env === "prerelease") {
    return `https://${subdomain}.prerelease.faire.dev`;
  } else if (env === "local") {
    return `http://localhost:${port}`;
  }
  return undefined;
}
