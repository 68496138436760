import { getUser } from "@faire/retailer-visitor-shared/serialized-data/getUser";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { UserRole } from "@faire/web-api--source/indigofair/data/UserRole";

const allowedRoles = [UserRole.MAKER, UserRole.MAKER_EDITOR];

const intersection = <T>(array1: T[], array2: T[]): T[] =>
  array1.filter((allowedRole) => array2.includes(allowedRole));

/**
 * @deprecated Use `useIsBrandPreview` instead
 */
export const getIsBrandPreview = (): boolean => {
  if (getGlobalProperty("editingAsBrandToken")) {
    return true;
  }

  const roles = getUser()?.roles;

  return areUserRolesAllowed(roles);
};

export const useIsBrandPreview = (): boolean => {
  if (getGlobalProperty("editingAsBrandToken")) {
    return true;
  }

  const roles = getUser()?.roles;

  return areUserRolesAllowed(roles);
};

export const areUserRolesAllowed = (
  roles: Array<keyof typeof UserRole> | undefined
): boolean => {
  if (!Array.isArray(roles)) {
    return false;
  }
  return intersection(allowedRoles, roles).length > 0;
};
