import { IPixelCustomDataParameters } from '@faire/web-api--source/indigofair/data/IPixelCustomDataParameters';

export namespace IRecordClientEventRequest {
  /**
   * Return an instance of IRecordClientEventRequest with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (
    partial?: Partial<IRecordClientEventRequest>
  ): IRecordClientEventRequest =>
    ({
      parameter_tokens: [],
      ...(partial ?? {}),
    }) as IRecordClientEventRequest;
}

export interface IRecordClientEventRequest {
  /**
   * The associated reference to the event schema of the event to be published.
   */
  event_key: string | undefined;
  /**
   * The tokens (brand token, retailer token, etc) associated with the event to be published.
   */
  parameter_tokens: Array<string>;
  /**
   * @protobufOption (faire.protobuf.validation) = TIMESTAMP_MILLIS
   */
  client_created_at: number | undefined;
  /**
   * The path to be stored in kinesis.events path column, it could be the old path for migrated event,
   * or it could be supplied for new event indicating the triggering page path (with/without params)
   */
  path: string | undefined;
  /**
   * Unique identifier for the event. Used for deduplication when forwarding the event to a 3rd-party system from
   * both the client and the server (for redundancy, e.g. Facebook Pixel)
   */
  event_id: string | undefined;
  /**
   * Subdomain the user was on when the event fired. inalytics should be accessed at events.api.<root domain>.
   * This was added to capture the subdomain for faire direct links. (https://github.com/Faire/backend/pull/19010)
   * In Mar 2023, we migrated brands off of subdomains and instead use a subfolder or query params otherwise.
   *       brandAlias.faire.com -> faire.com/direct/brandAlias.
   *       brandAlias.faire.com/product/p_123 -> faire.com/product/p_123?fdb=brandAlias (FD-125013)
   * We'll keep this field present to for the time being for compatibility with our event pipeline.
   */
  subdomain: string | undefined;
  pixel_custom_data_parameters: IPixelCustomDataParameters | undefined;
  /**
   * If we are recording a trace event, pass in sourceEventHash to indicate which source event it's following
   */
  source_event_hash: string | undefined;
  /**
   * The duration from the start of the parent event to the trace event being recorded with this request.
   * This should be measured in milliseconds.
   */
  timing: number | undefined;
  canary_role: string | undefined;
  pod_template_hash: string | undefined;
}
