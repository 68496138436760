import { useSettingDecentralizePageData } from "@faire/retailer-visitor-shared/settings/useSettingDecentralizePageData";
import { getGlobalProperty } from "@faire/web--source/common/globals/getGlobalProperty";
import { useQuery as useNavigationHashcodeQuery } from "@faire/web-api--source/endpoints/www/api/page-data/navigation-hashcode/get-hooks";

export const NAVIGATION_TREE_HASH_CODE_PROPERTY_NAME = "navigationTreeHashCode";
export const getNavigationTreeHashCode = (): string | undefined =>
  getGlobalProperty<string>(NAVIGATION_TREE_HASH_CODE_PROPERTY_NAME);

export const useNavigationTreeHashCode = (): string | undefined => {
  const shouldDecentralizePageData = useSettingDecentralizePageData();
  const { data: navigationHashcodeData } = useNavigationHashcodeQuery({
    enabled: shouldDecentralizePageData,
    refetchOnMount: false,
    staleTime: Infinity,
  });

  return shouldDecentralizePageData
    ? navigationHashcodeData?.navigation_tree_hash_code
    : getNavigationTreeHashCode();
};
