import batchDeprecatedEvents from "@faire/web-api--source/endpoints/events.api/api/v2/events/post";
import { IPublishUserEventsRequest } from "@faire/web-api--source/indigofair/data/IPublishUserEventsRequest";
import { IUserEvent } from "@faire/web-api--source/indigofair/data/IUserEvent";

import { BatchEventsQueue } from "./BatchEventsQueue";

export class UserEventsQueue extends BatchEventsQueue<IUserEvent> {
  constructor() {
    super();
  }

  batchEvents = async (events: IUserEvent[]): Promise<void> => {
    await batchDeprecatedEvents(
      IPublishUserEventsRequest.build({
        events,
        is_validation: false,
      })
    );
  };
}
