import { QueryParameters as PersonalizedCategoriesParams } from "@faire/web-api--source/endpoints/www/api/v3/categories/personalized/retailerToken/get";

export const getPersonalizedCategoriesQueryParams = (
  locale: string | undefined,
  hashCode: string | undefined,
  isInsider: boolean,
  cacheBust?: string
): PersonalizedCategoriesParams => {
  const params = PersonalizedCategoriesParams.build({
    cacheBust,
    hashCode,
    locale,
  });
  // DO NOT REMOVE THIS! This is needed because the "status" query doesn't actually exist on the backend,
  // and it was added just to fetch new cache as this problem as caused by cache
  // https://github.com/Faire/web-retailer/pull/23823.
  if (isInsider) {
    (params as unknown as { status: "enrolled" }).status = "enrolled";
  }

  return params;
};
