import { ResponsiveValues } from "@faire/web--source/slate/Theme/ResponsiveValues";
import { useMemo } from "react";

import { useViewport } from "./useViewport";
import { MinWidthBreakpoints } from "./viewport-utils/viewport-utils";

export const useResponsiveValue = <T>(
  {
    mobileAndAbove,
    tabletAndAbove,
    desktopAndAbove,
    xLargeAndAbove,
    xxLargeDesktop,
  }: ResponsiveValues<T>,
  minWidthBreakpoints?: MinWidthBreakpoints
): T => {
  const {
    isXXLargeDesktop,
    isXLargeAndAbove,
    isDesktopAndAbove,
    isTabletAndAbove,
  } = useViewport(minWidthBreakpoints);

  return useMemo(() => {
    if (isXXLargeDesktop) {
      return (
        xxLargeDesktop ??
        xLargeAndAbove ??
        desktopAndAbove ??
        tabletAndAbove ??
        mobileAndAbove
      );
    }
    if (isXLargeAndAbove) {
      return (
        xLargeAndAbove ?? desktopAndAbove ?? tabletAndAbove ?? mobileAndAbove
      );
    }
    if (isDesktopAndAbove) {
      return desktopAndAbove ?? tabletAndAbove ?? mobileAndAbove;
    }
    if (isTabletAndAbove) {
      return tabletAndAbove ?? mobileAndAbove;
    }
    return mobileAndAbove;
  }, [
    desktopAndAbove,
    isDesktopAndAbove,
    isTabletAndAbove,
    isXLargeAndAbove,
    isXXLargeDesktop,
    mobileAndAbove,
    tabletAndAbove,
    xLargeAndAbove,
    xxLargeDesktop,
  ]);
};
