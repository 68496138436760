import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';

/**
 * Generated from Jersey path "/retailer/{retailerToken}"
 *
 * @deprecated Use the base path /
 * Product Area: IDV
 */
export const route = '/retailer/:retailerToken';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/retailer/{retailerToken}',
  productArea: 'IDV',
  pod: 'FAIRE_POD_RETAILER_ONBOARDING',
  pillar: 'FAIRE_PILLAR_RETAILER_GROWTH',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

/**
 * @deprecated Use the base path /
 */
export function path(retailerToken: string) {
  const compiledPath = toPath({
    retailerToken,
  });
  return compiledPath;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (retailerToken: string): string => {
  return faireBaseUrl(3000) + path(retailerToken);
};

export type PathParameters = {
  retailerToken: string;
};

export default path;
