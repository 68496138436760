import { request } from '@faire/web-api--source';
import { IPublishUserEventsRequest } from '@faire/web-api--source/indigofair/data/IPublishUserEventsRequest';
import { PublishUserEventsResponse } from '@faire/web-api--source/indigofair/data/PublishUserEventsResponse';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/v2/events`;
};

const subdomain = 'events.api';

export const build = (
  data: IPublishUserEventsRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    subdomain,
    ignoreForRobots: true,
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/events';

/**
 * @deprecated This endpoint is still being tested. Use /api/event to send individual non-PETE events instead.
 * Product Area: CLIENT_EVENTS
 * Ignored (not called) during bot sessions.
 */
function execute(
  data: IPublishUserEventsRequest
): Promise<PublishUserEventsResponse>;
function execute(
  data: IPublishUserEventsRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<PublishUserEventsResponse>>;
function execute(
  data: IPublishUserEventsRequest,
  options?: EndpointOptions
): Promise<PublishUserEventsResponse>;
function execute(
  data: IPublishUserEventsRequest,
  options?: EndpointOptions
): Promise<
  PublishUserEventsResponse | SuccessResponse<PublishUserEventsResponse>
> {
  const { route, ...requestOptions } = build(data, options);
  return request<PublishUserEventsResponse>(requestOptions);
}
execute[PATH] = 'endpoints/events.api/api/v2/events/post';
export default execute;
