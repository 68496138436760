import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';

export type QueryParameters = {
  refC: string | undefined;
  refSC: string | undefined;
  refS: string | undefined;
  refF: string | undefined;
  refOrigin: string | undefined;
  refP: string | undefined;
  refReqId: string | undefined;
  refType: string | undefined;
  sortingHint: string | undefined;
  impressionId: string | undefined;
  q: string | undefined;
  cat: string | undefined;
  sortBy: string | undefined;
  page: string | undefined;
  filters: string | undefined;
  product: string | undefined;
  scrollToTop: string | undefined;
  scrollToGrid: string | undefined;
  collections: string | undefined;
  trackEventToken: string | undefined;
  story: string | undefined;
  requestSamples: string | undefined;
  modalType: string | undefined;
  fc: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/brand/{brandToken:([^/])*}/{page:\d+}"
 *
 * Legacy route for brand page, with pagination using the path.
 * @deprecated Use query param like ?page=2 instead
 * Product Area: BRAND_PAGE
 */
export const route = '/brand/:brandToken([^/]*)/:page(\d+)';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/brand/{brandToken:([^/])*}/{page:\d+}',
  productArea: 'BRAND_PAGE',
  pod: 'FAIRE_POD_EVALUATE',
  pillar: 'FAIRE_PILLAR_DISCOVERY',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

/**
 * Legacy route for brand page, with pagination using the path.
 * @deprecated Use query param like ?page=2 instead
 */
export function path(
  brandToken: string,
  page: string,
  queryParams?: Partial<QueryParameters>
) {
  const compiledPath = toPath({
    brandToken,
    page,
  });
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (
  brandToken: string,
  page: string,
  queryParams?: Partial<QueryParameters>
): string => {
  return faireBaseUrl(3000) + path(brandToken, page, queryParams);
};

export type PathParameters = {
  brandToken: string;
  page: string;
};

export default path;
