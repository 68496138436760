"use client";

import { getLocation } from "@faire/web--source/common/globals/getLocation";
import { appendFaireDirectBrandAliasQueryParam } from "@faire/web--source/common/routes/faire-direct/appendFaireDirectBrandAliasQueryParam";
import {
  ILinkComponentProps,
  RoutingContext,
} from "@faire/web--source/ui/routing/RoutingContext";
import {
  matchLocale,
  prependLocaleToUrl,
} from "@faire/web--source/ui/routing/util";
import * as H from "history";
import React from "react";
import {
  // eslint-disable-next-line @faire-web/no-restricted-imports
  Link as ReactLink,
  LinkProps as ReactLinkProps,
  useHistory,
} from "react-router-dom";

export type ILinkProps<S = H.LocationState> = Omit<ReactLinkProps<S>, "to"> & {
  to: H.LocationDescriptor<S>;
  skipPrependLocale?: boolean;
  prefetch?: boolean;
};

/**
 * Thin wrapper around React Links (from react-router-dom).
 * The thin wrapper allows us to globally augment all Faire URLs, if we want to.
 */
export const Link =
  <S extends H.LocationState = H.LocationState>(
    props: ILinkProps<S> & React.RefAttributes<HTMLAnchorElement>,
  ) => {
    const {
      faireDirectBrandAlias,
      faireDirectBrandToken,
      Link = ReactLink,
      useRouterOrHistory = useHistory,
      ...routingContext
    } = React.useContext(RoutingContext);
    const { to, skipPrependLocale, ...otherProps } = props;
    const history = useRouterOrHistory();

    const shouldAppendFaireDirectQueryParam =
      faireDirectBrandAlias && faireDirectBrandToken;

    let toStr: string, augmentedToStr: string;
    let augmentedTo: H.LocationDescriptor<S>;
    if (typeof to === "object") {
      augmentedTo = to;
      augmentedTo.pathname = prependLocaleToUrl(
        `${to.pathname}`,
        routingContext
      );

      if (shouldAppendFaireDirectQueryParam) {
        augmentedTo.search = appendFaireDirectBrandAliasQueryParam(
          augmentedTo.pathname,
          faireDirectBrandAlias,
          faireDirectBrandToken,
          // We need to fall back on an empty string because we want appendFaireDirectBrandAliasQueryParam
          // to return the search, *not* the path.
          augmentedTo.search ?? ""
        );
      }

      toStr = to.pathname ?? "";
      augmentedToStr = augmentedTo.pathname ?? "";
    } else {
      /**
       * for bots language selectors, we do not want to prependLocale to US links
       * for example, if localized path is /careers, we want to direct bots to /careers, not /en/careers
       */
      augmentedTo = skipPrependLocale
        ? to
        : prependLocaleToUrl(`${to}`, routingContext);

      if (shouldAppendFaireDirectQueryParam) {
        augmentedTo = appendFaireDirectBrandAliasQueryParam(
          augmentedTo,
          faireDirectBrandAlias,
          faireDirectBrandToken
        );
      }

      toStr = to;
      augmentedToStr = augmentedTo;
    }

    const linkProps: ILinkComponentProps = {
      innerRef: props.ref,
      to: augmentedTo,
      onClick: () => {
        if (!matchLocale(toStr, routingContext?.locale)) {
          history.push(augmentedToStr);
          getLocation()?.reload();
        }
      },
      ...otherProps,
    };

    return <Link {...linkProps} />;
  };

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  prefetch?: boolean;
};

/**
 * Thin wrapper around `<a>` tags.
 * The thin wrapper allows us to globally augment all Faire URLs, if we want to.
 */
export const Anchor: React.FC<AnchorProps & React.RefAttributes<HTMLAnchorElement>> = (
  props
) => {
  const { faireDirectBrandAlias, faireDirectBrandToken, ...routingContext } =
    React.useContext(RoutingContext);
    const { children, href, ...otherProps } = props;

    const potentiallyPrependLocaleHref = href?.startsWith("/")
      ? prependLocaleToUrl(href, routingContext)
      : href;

    const shouldAppendFaireDirectQueryParam =
      faireDirectBrandAlias &&
      faireDirectBrandToken &&
      !!potentiallyPrependLocaleHref;

    const augmentedHref = shouldAppendFaireDirectQueryParam
      ? appendFaireDirectBrandAliasQueryParam(
          potentiallyPrependLocaleHref ?? "",
          faireDirectBrandAlias,
          faireDirectBrandToken
        )
      : potentiallyPrependLocaleHref;

    return (
      // eslint-disable-next-line @faire/no-raw-anchor-tag
      <a ref={props.ref} href={augmentedHref} {...otherProps}>
        {children}
      </a>
    );
  };
