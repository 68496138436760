import { IProduct } from '@faire/web-api--source/indigofair/data/IProduct';

export namespace IClickInfo {
  export enum Type {
    BRAND = 'BRAND',
    PRODUCT = 'PRODUCT',
    FLASH_SALE = 'FLASH_SALE',
    CATEGORY = 'CATEGORY',
    LAYOUT_ELEMENT = 'LAYOUT_ELEMENT',
    SEARCH_QUERY = 'SEARCH_QUERY',
  }

  export namespace IProductMetadata {
    /**
     * Return an instance of IProductMetadata with the provided fields. Any field not
     * listed will be populated with an empty value (an empty array,
     * or undefined, whichever is valid for the type of the value.)
     */
    export const build = (
      partial?: Partial<IProductMetadata>
    ): IProductMetadata =>
      ({
        promoted_by: [],
        ...(partial ?? {}),
      }) as IProductMetadata;
  }

  /**
   * This is used for additional product metadata related to boost tracking for now.
   */
  export interface IProductMetadata {
    promoted_by: Array<keyof typeof IProduct.PromotedType>;
  }

  /**
   * Return an instance of IClickInfo with the provided fields. Any field not
   * listed will be populated with an empty value (an empty array,
   * or undefined, whichever is valid for the type of the value.)
   */
  export const build = (partial?: Partial<IClickInfo>): IClickInfo =>
    ({
      ...(partial ?? {}),
    }) as IClickInfo;
}

export interface IClickInfo {
  token: string | undefined;
  /**
   * If the response where this entity came from has an requestId we *must* pass it back here.
   */
  request_id: string | undefined;
  type: keyof typeof IClickInfo.Type | undefined;
  /**
   * This supplements and augments the request ID in cases where there isn't a clear definition of a request/response
   * leading to an impression. For example, the cart doesn't have a clear concept of a request ID.
   * Container names are typically snake case names, like right_hand_side_cart.
   */
  container_name: string | undefined;
  product_metadata: IClickInfo.IProductMetadata | undefined;
}
