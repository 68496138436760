import { COOKIE_CURRENCY_OVERRIDE } from "@faire/web--source/common/consts/COOKIE_CURRENCY_OVERRIDE";
import { HASHED_SESSION_TOKEN_KEY } from "@faire/web--source/common/consts/HASHED_SESSION_TOKEN_KEY";
import { STRING_ID_MODE_COOKIE } from "@faire/web--source/common/consts/IF_SHOW_TEXT_VARIANT_ID";
import { LOCALHOST_EXTENSION_SECRET_KEY } from "@faire/web--source/common/consts/LOCALHOST_EXTENSION_SECRET_KEY";
import { PRODUCTION_EXTENSION_SECRET_KEY } from "@faire/web--source/common/consts/PRODUCTION_EXTENSION_SECRET_KEY";
import { STAGING_EXTENSION_SECRET_KEY } from "@faire/web--source/common/consts/STAGING_EXTENSION_SECRET_KEY";
import { Currency } from "@faire/web--source/common/currency/Currency";
import { GIBBERISH_TEXT_MODE_COOKIE } from "@faire/web--source/common/localization/gibberish";
import { Language } from "@faire/web-api--source/indigofair/languages/Language";
import Cookies from "js-cookie";

import { COOKIE_CLIENT_WIDTH } from "./consts/COOKIE_CLIENT_WIDTH";
import { COOKIE_LOCALE_OVERRIDE } from "./consts/COOKIE_LOCALE_OVERRIDE";
import { SHOP_PREVIEW_OVERRIDE_KEY } from "./consts/SHOP_PREVIEW_OVERRIDE_KEY";

export type Cookies = {
  [COOKIE_LOCALE_OVERRIDE]: Language | string;
  [SHOP_PREVIEW_OVERRIDE_KEY]: Language | string;
  [SHOP_PREVIEW_OVERRIDE_KEY]: Language | string;
  [STRING_ID_MODE_COOKIE]: boolean;
  [GIBBERISH_TEXT_MODE_COOKIE]: boolean;
  [COOKIE_CURRENCY_OVERRIDE]: Currency;
  [HASHED_SESSION_TOKEN_KEY]: string;
  [STAGING_EXTENSION_SECRET_KEY]: string;
  [PRODUCTION_EXTENSION_SECRET_KEY]: string;
  [LOCALHOST_EXTENSION_SECRET_KEY]: string;
  [COOKIE_CLIENT_WIDTH]: number;
};

// According to the GDPR’s definition of persistent cookies, set for 12 months
const COOKIE_EXPIRES_IN_DAYS = 365;
const OKTA_SSO_KEY_LIST = [
  PRODUCTION_EXTENSION_SECRET_KEY,
  STAGING_EXTENSION_SECRET_KEY,
  LOCALHOST_EXTENSION_SECRET_KEY,
  HASHED_SESSION_TOKEN_KEY,
];

export const toggleCookie = <K extends keyof Cookies>(
  key: K,
  value: Cookies[K] | undefined
) => {
  if (value) {
    setCookies(key, value);
  } else {
    removeCookie(key);
  }
};

export const setCookies = <K extends keyof Cookies>(
  key: K,
  value: Cookies[K]
) => {
  if (OKTA_SSO_KEY_LIST.includes(key)) {
    const now = new Date();
    const threeMinutesLater = new Date(now.getTime() + 3 * 60 * 1000);
    Cookies.set(key, value as string, { expires: threeMinutesLater });
  } else {
    Cookies.set(key, value as string, { expires: COOKIE_EXPIRES_IN_DAYS });
  }
};

export const getCookies = <K extends keyof Cookies>(
  key: K
): Cookies[K] | undefined => Cookies.get(key) as Cookies[K];

export const removeCookie = <K extends keyof Cookies>(key: K) =>
  Cookies.remove(key);
