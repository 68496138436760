import { request } from '@faire/web-api--source';
import { AssignSettingResponse } from '@faire/web-api--source/faire/settings_boss/AssignSettingResponse';
import { IBatchAssignSettingRequest } from '@faire/web-api--source/faire/settings_boss/IBatchAssignSettingRequest';
import {
  EndpointOptions,
  PATH,
  RequestOptions,
  SuccessResponse,
} from '@faire/web-api--source/types';

export const path = () => {
  return `/api/v2/setting/batch_assign`;
};

export const build = (
  data: IBatchAssignSettingRequest,
  options?: EndpointOptions
): RequestOptions & { route: string } => {
  return {
    route,
    url: path(),
    method: 'POST',
    ignoreDuringPrerender: true,
    ignoreForRobots: true,
    data,
    ...options,
    headers: {
      'content-type': 'application/json',
      ...options?.headers,
    },
  };
};
/**
 * path-to-regexp definition of this endpoint's route
 */
export const route = '/api/v2/setting/batch_assign';

/**
 *     Assign a batch of setting values for the current user.
 * Product Area: SETTINGS
 * Ignored (not called) during prerender sessions.
 * Ignored (not called) during bot sessions.
 */
function execute(
  data: IBatchAssignSettingRequest
): Promise<AssignSettingResponse>;
function execute(
  data: IBatchAssignSettingRequest,
  options?: EndpointOptions & { rawResponse: true }
): Promise<SuccessResponse<AssignSettingResponse>>;
function execute(
  data: IBatchAssignSettingRequest,
  options?: EndpointOptions
): Promise<AssignSettingResponse>;
function execute(
  data: IBatchAssignSettingRequest,
  options?: EndpointOptions
): Promise<AssignSettingResponse | SuccessResponse<AssignSettingResponse>> {
  const { route, ...requestOptions } = build(data, options);
  return request<AssignSettingResponse>(requestOptions);
}
execute[PATH] = 'endpoints/www/api/v2/setting/batch_assign/post';
export default execute;
