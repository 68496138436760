import { Core } from "@faire/design-tokens";
import { isResponsiveProp } from "@faire/web--source/slate/Theme/ResponsiveProp";
import type {
  TypographyProps,
  TypographyVariants,
} from "@faire/web--source/slate/Typography";
import { cn } from "@faire/web--source/tools/cn";
import {
  ResponsiveContentV2,
  IProps as IResponsiveContentProps,
} from "@faire/web--source/ui/ResponsiveContentV2";
import React, {
  CSSProperties,
  ForwardedRef,
  HTMLAttributes,
  LabelHTMLAttributes,
} from "react";

import Color from "../Color";
import { ResponsiveValues } from "../Theme/ResponsiveValues";

// eslint-disable-next-line import/no-unassigned-import
import "./native-styles.css";
import { ParagraphTypographyProps } from "./Types";
import { FONT_RAMP, variantTagMap } from "./utils";

export type Props = (
  | ({ as: "label" } & LabelHTMLAttributes<HTMLElement>)
  | HTMLAttributes<HTMLElement>
) &
  (TypographyProps | ParagraphTypographyProps) & {
    children?: React.ReactNode;
  };

const resolveVariant = (
  variant: TypographyVariants,
  textDecoration?: boolean
): TypographyVariants => {
  if (variant === "paragraphSansRegular" || variant === "paragraphSansMedium") {
    return variant;
  }

  if (textDecoration) {
    return "paragraphSansRegular";
  }

  return variant;
};

const resolveColor = (color: string | undefined) => {
  if (color && color in Color) {
    // @ts-expect-error this is a hack
    return Color[color as unknown as any];
  }

  if (!color) {
    return Core.text.default;
  }

  return color;
};

const BaseTypography: React.FC<
  Omit<Props, "variant"> & {
    variant: TypographyVariants;
  } & React.RefAttributes<HTMLElement>
> = ({
  as,
  variant = "paragraphSansRegular",
  align,
  color,
  truncate,
  maxLines,
  textDecoration,
  strikethrough,
  children,
  ref,
  ...rest
}) => {
  const vars: Record<string, string> = {};
  const classNames: string[] = [];

  // Alignment
  if (isResponsiveProp(align)) {
    vars["--f_t_text_align_mobile"] = align.mobileAndAbove;
    vars["--f_t_text_align_tablet"] =
      align.tabletAndAbove != null
        ? align.tabletAndAbove
        : vars["--f_t_text_align_mobile"];
    vars["--f_t_text_align_desktop"] =
      align.desktopAndAbove != null
        ? align.desktopAndAbove
        : vars["--f_t_text_align_tablet"];
    vars["--f_t_text_align_xlarge"] =
      align.xLargeAndAbove != null
        ? align.xLargeAndAbove
        : vars["--f_t_text_align_desktop"];
    vars["--f_t_text_align_xxlarge"] =
      align.xxLargeDesktop != null
        ? align.xxLargeDesktop
        : vars["--f_t_text_align_xlarge"];
    classNames.push("f_t_variable_text_align");
  } else if (align != null) {
    vars["--f_t_text_align_mobile"] = align;
    classNames.push("f_t_single_value_text_align");
  }

  // Variant
  variant = resolveVariant(variant, Boolean(textDecoration));
  classNames.push(`f_t_${variant}`);
  const variantTag = getVariantTag(variant);
  const tag = as || variantTag || "p";

  color = resolveColor(color);

  // Text decoration
  if (textDecoration) {
    classNames.push(`f_t_${textDecoration}`);
  }

  const className = cn(
    "f_t_base",
    truncate && "f_t_truncate",
    maxLines && "f_t_maxLines",
    strikethrough && "f_t_strikethrough",
    color === "inherit" ? "f_t_inheritColor" : "f_t_color",
    rest.className,
    classNames
  );

  const rampLineHeight = FONT_RAMP[variant]["line-height"];

  const style: CSSProperties = {
    ...{
      ...vars,
      "--f_t_color": color === "inherit" ? undefined : color,
      "--f_t_maxLines": maxLines ? String(maxLines) : "",
      "--f_t_maxHeight":
        rampLineHeight && maxLines
          ? `calc(${maxLines} * ${rampLineHeight})`
          : undefined,
      "--f_t_decorationColor": Core.border.subdued,
    },
    ...rest.style,
  };

  return React.createElement(
    tag,
    {
      ...rest,
      style,
      className,
      ref: ref as ForwardedRef<HTMLElement | null>,
    },
    children
  );
};

type IResponsiveOptions = Omit<IResponsiveContentProps, "noJS" | "flat">;

export const Typography: React.FC<Props & React.RefAttributes<HTMLElement>> = (
  props
) => {
  const { variant = "paragraphSansRegular", align, ref, ...rest } = props;
  if (isResponsiveProp(variant)) {
    // Reduce the incoming props to the smallest number possible of ResponsiveContentV2 props
    const responsiveProps: Array<keyof ResponsiveValues<unknown>> = [
      "mobileAndAbove",
      "tabletAndAbove",
      "desktopAndAbove",
      "xLargeAndAbove",
      "xxLargeDesktop",
    ];
    const responsiveContentOnlyProps: Array<keyof IResponsiveOptions> = [
      "mobile",
      "tablet",
      "desktop",
      "xlarge",
      "xxlarge",
    ];
    const responsiveContentOrAboveProps: Array<
      keyof IResponsiveOptions | null
    > = [
      null,
      "tabletAndAbove",
      "desktopAndAbove",
      "xLargeAndAbove",
      "xxlarge",
    ];

    const props: IResponsiveOptions = {};
    const highestIdx = Object.keys(variant)
      .map((key) => (responsiveProps as Array<string>).indexOf(key))
      .reduce((a, b) => Math.max(a, b));
    for (const [key, subvariant] of Object.entries(variant)) {
      const idx = (responsiveProps as Array<string>).indexOf(key);
      const subtypography = (
        <BaseTypography
          variant={subvariant}
          align={align}
          {...rest}
          ref={ref}
        />
      );
      if (idx < highestIdx) {
        props[responsiveContentOnlyProps[idx]!] = subtypography;
        for (
          let j = idx + 1;
          !Object.keys(variant).includes(responsiveProps[j] ?? "") &&
          j < responsiveProps.length;
          j++
        ) {
          props[responsiveContentOnlyProps[j]!] = subtypography;
        }
      } else {
        props[responsiveContentOrAboveProps[idx]!] = subtypography;
      }
    }
    return <ResponsiveContentV2 {...props} flat={true} />;
  } else {
    return (
      <BaseTypography variant={variant} align={align} {...rest} ref={ref} />
    );
  }
};

const getVariantTag = (variant: TypographyProps["variant"]) =>
  isResponsiveProp(variant)
    ? variantTagMap[variant.mobileAndAbove]
    : variantTagMap[variant!];

export const Span: React.FC<Props & React.RefAttributes<HTMLSpanElement>> = (
  props
) => {
  return <Typography as="span" {...props} />;
};
