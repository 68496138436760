import { useRetailer } from "@faire/retailer-visitor-shared/hooks/retailer/useRetailer";
import { useSettingMigrateRetailerStore } from "@faire/retailer-visitor-shared/settings/useSettingMigrateRetailerStore";
import { useRetailerStore } from "@faire/retailer-visitor-shared/stores/domain/RetailerStore";
import { IRetailer } from "@faire/web-api--source/indigofair/data/IRetailer";

const useInsiderControl = () => {
  const {
    isInsider,
    isEligibleForInsider,
    shouldShowInsider,
    insiderMembershipState,
    shouldShowInsiderSignupIncentive,
  } = useRetailerStore([
    "isInsider",
    "isEligibleForInsider",
    "shouldShowInsider",
    "insiderMembershipState",
    "shouldShowInsiderSignupIncentive",
  ]);

  return {
    isInsider,
    isEligibleForInsider,
    shouldShowInsider,
    insiderMembershipState,
    shouldShowInsiderSignupIncentive,
  };
};

const useInsiderTreatment = () => {
  const { retailer } = useRetailer();

  const insiderMembershipState =
    retailer?.insider_membership_state ??
    IRetailer.InsiderMembershipState.NOT_ELIGIBLE;
  const isInsider =
    insiderMembershipState === IRetailer.InsiderMembershipState.ACTIVE;
  const isEligibleForInsider =
    insiderMembershipState === IRetailer.InsiderMembershipState.ELIGIBLE ||
    insiderMembershipState === IRetailer.InsiderMembershipState.CHURNED;
  const shouldShowInsider =
    insiderMembershipState !== IRetailer.InsiderMembershipState.NOT_ELIGIBLE;
  const shouldShowInsiderSignupIncentive =
    !!retailer?.show_insider_signup_offer;

  return {
    isInsider,
    isEligibleForInsider,
    shouldShowInsider,
    insiderMembershipState,
    shouldShowInsiderSignupIncentive,
  };
};

export const useInsider = () => {
  const shouldUseHooksMigration = useSettingMigrateRetailerStore();
  if (shouldUseHooksMigration) {
    // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
    return useInsiderTreatment();
  }
  // eslint-disable-next-line react-compiler/react-compiler, react-hooks/rules-of-hooks
  return useInsiderControl();
};
