import batchPeteEvents from "@faire/web-api--source/endpoints/events.api/api/client-events/post";
import { IRecordClientEventRequest } from "@faire/web-api--source/indigofair/data/IRecordClientEventRequest";
import { IRecordClientEventsRequest } from "@faire/web-api--source/indigofair/data/IRecordClientEventsRequest";

import { BatchEventsQueue } from "./BatchEventsQueue";

export class PeteEventsQueue extends BatchEventsQueue<IRecordClientEventRequest> {
  constructor(retry_timeout_multiplier: number = 100) {
    super(retry_timeout_multiplier);
  }

  batchEvents = async (events: IRecordClientEventRequest[]): Promise<void> => {
    await batchPeteEvents(
      IRecordClientEventsRequest.build({
        events,
        is_validation: false,
      })
    );
  };
}
