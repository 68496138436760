import { SettingConst } from '@faire/web-api--source/types';

/**
 * Controls whether or not page data is fetched in the root layout or by individual pages
 */
export const NEXTJS_DECENTRALIZE_PAGE_DATA_250303 =
  'NEXTJS_DECENTRALIZE_PAGE_DATA_250303' as SettingConst<
    'NEXTJS_DECENTRALIZE_PAGE_DATA_250303',
    boolean,
    'RETAILER' | 'RETAILER_HEAD_TAGS' | 'VISITOR_WEB' | 'VISITOR_WEB_HEAD_TAGS'
  >;
