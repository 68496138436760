"use client";

import { Core } from "@faire/design-tokens";
import { StandardPropertiesHyphen } from "csstype";
import { css, DefaultTheme } from "styled-components";

import Color from "../Color";
import { buildResponsiveMediaQueries } from "../Theme/ResponsiveCss";
import {
  highVisSlateComponent,
  HIGH_VIS_FILTER,
} from "../utils/highVisSlateComponent";

import { fontRamp } from "./Themes";
import {
  TypographyVariant,
  TypographyProps,
  TypographyTags,
  TypographyVariants,
  TextDecoration as TextDecorationType,
  ParagraphTypographyProps,
} from "./Types";

export const variantTagMap: { [key in TypographyVariants]: TypographyTags } = {
  displayXLSerifRegular: "h1",
  displayLSerifRegular: "h2",
  displayMSerifRegular: "h3",
  displaySSansSemiBold: "h4",
  displaySSansRegular: "h4",
  displaySSerifRegular: "h4",
  pageHeaderSerifRegular: "h4",
  sectionHeaderSansMedium: "h5",
  subheadingSansRegular: "h5",
  subheadingSansMedium: "h5",
  paragraphSansRegular: "p",
  paragraphSansMedium: "p",
  labelSansRegular: "p",
  labelSansMedium: "p",
};

export const objectToCssString = (variant: TypographyVariant) =>
  variant
    ? Object.keys(variant)
        .map((key) => `${key}: ${variant[key as keyof TypographyVariant]};`)
        .join("")
    : "";

export const Align = (align: StandardPropertiesHyphen["text-align"]) =>
  `text-align: ${align};`;

export const Truncate = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Strikethrough = `
  text-decoration: line-through;
`;

export const TextDecoration = (textDecoration: TextDecorationType) => {
  switch (textDecoration) {
    case "underline":
      return `
        text-decoration: underline;
        text-underline-offset: 0.25rem;
      `;
    case "strikethrough":
      return `text-decoration: line-through;`;
    case "dottedUnderline":
      return `
        text-underline-offset: 0.25rem;
        text-decoration-line: underline;
        -webkit-text-decoration-line: underline;
        text-decoration-color: var(--f_t_decorationColor);
        -webkit-text-decoration-color: var(--f_t_decorationColor);
        text-decoration-style: dotted;
        -webkit-text-decoration-style: dotted;
      `;
    default:
      return "";
  }
};

export const MaxLines = (
  maxLines: number,
  lineHeight: TypographyVariant["line-height"]
) => `
  -webkit-line-clamp: ${maxLines};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(${lineHeight} * ${maxLines}),
  text-overflow: ellipsis;
`;

export const TypographyStyles = ({
  theme,
  variant = "paragraphSansRegular",
  color = Core.text.default,
  align,
  truncate,
  strikethrough,
  maxLines,
  textDecoration,
}: (TypographyProps | ParagraphTypographyProps) & { theme?: DefaultTheme }) => {
  const typographyTheme =
    theme && Object.entries(theme).length !== 0 && theme["typography"]
      ? theme["typography"]
      : fontRamp;
  return css`
    margin: 0;
    padding: 0;
    font-variant-numeric: lining-nums tabular-nums;
    color: ${Color[color as keyof typeof Color] || color};
    ${buildResponsiveMediaQueries(variant, (value) =>
      objectToCssString(typographyTheme["fonts"][value])
    )}
    ${align ? buildResponsiveMediaQueries(align, Align) : ""}
    ${truncate ? Truncate : ""}
    ${strikethrough ? Strikethrough : ""}
    ${highVisSlateComponent(HIGH_VIS_FILTER.TYPOGRAPHY)}
    ${maxLines && maxLines > 0
      ? buildResponsiveMediaQueries(variant, (value) =>
          MaxLines(maxLines, typographyTheme["fonts"][value]["line-height"])
        )
      : ""}
    ${textDecoration ? TextDecoration(textDecoration) : ""}
  `;
};
