"use client";

import React, { createContext, useContext } from "react";

/**
 * This provides clients with environment information specific to the runtime application.
 * Some apps use "globals" to store similar information, but we're moving away from that pattern.
 */

// TODO: Once we add the provider to every app, we can use zod to enforce the shape of the value.
type EnvContext = {
  appName: string | undefined;
  envName: string | undefined;
};

const Context = createContext<EnvContext>({
  appName: undefined,
  envName: undefined,
});

type Props = {
  children: React.ReactNode;
  value: EnvContext;
};

export const EnvProvider: React.FC<Props> = ({ children, value }) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useEnv = () => {
  return useContext(Context);
};
