import { type IRouteMetadata } from '@faire/web-api--source/Route';
import { faireBaseUrl } from '@faire/web--source/common/routes/faireBaseUrl';
import { compile } from 'path-to-regexp';
import qs from 'query-string';

export type QueryParameters = {
  refC: string | undefined;
  refSC: string | undefined;
  refS: string | undefined;
  refF: string | undefined;
  refOrigin: string | undefined;
  refP: string | undefined;
  refReqId: string | undefined;
  refType: string | undefined;
  sortingHint: string | undefined;
  impressionId: string | undefined;
  q: string | undefined;
  cat: string | undefined;
  sortBy: string | undefined;
  page: string | undefined;
  filters: string | undefined;
  product: string | undefined;
  scrollToTop: string | undefined;
  scrollToGrid: string | undefined;
  collections: string | undefined;
  trackEventToken: string | undefined;
  story: string | undefined;
  requestSamples: string | undefined;
  modalType: string | undefined;
  fc: string | undefined;
};

export namespace QueryParameters {
  export const build = (partial: Partial<QueryParameters>): QueryParameters =>
    partial as QueryParameters;

  /**
   * Parse the given raw query string or partially-parsed react-router-dom queryParams object.
   */
  export function parse(
    search: string | { [K in keyof QueryParameters]?: string } = ''
  ): QueryParameters {
    const rawParsed =
      typeof search === 'string' ? qs.parse(search) : (search ?? {});
    const parsed: any = { ...rawParsed };

    return parsed as QueryParameters;
  }
}

/**
 * Generated from Jersey path "/retailer/{retailerToken}/brand/{brandToken:([^/])*}"
 *
 * @deprecated Use /brand/{brandToken} (from BrandPageHomeResource)
 * Product Area: RETAILER_BRANDS_MANAGEMENT
 */
export const route = '/retailer/:retailerToken/brand/:brandToken([^/]*)';

export const props: IRouteMetadata & { path: string } = {
  path: route,
  templatedPath: '/retailer/{retailerToken}/brand/{brandToken:([^/])*}',
  productArea: 'RETAILER_BRANDS_MANAGEMENT',
  pod: 'FAIRE_POD_RETAILER_ORDER_PLACEMENT',
  pillar: 'FAIRE_PILLAR_RETAILER_TOOLS_AND_PROGRAMS',
};

export const toPath = compile(route, {
  encode: encodeURIComponent,
});

/**
 * @deprecated Use /brand/{brandToken} (from BrandPageHomeResource)
 */
export function path(
  retailerToken: string,
  brandToken: string,
  queryParams?: Partial<QueryParameters>
) {
  const compiledPath = toPath({
    retailerToken,
    brandToken,
  });
  const queryString = queryParams ? qs.stringify(queryParams) : '';
  return `${compiledPath}${queryString ? `?${queryString}` : ''}`;
}

/**
 * Build a full URL of the route (including the base faire URL).
 */
export const url = (
  retailerToken: string,
  brandToken: string,
  queryParams?: Partial<QueryParameters>
): string => {
  return faireBaseUrl(3000) + path(retailerToken, brandToken, queryParams);
};

export type PathParameters = {
  retailerToken: string;
  brandToken: string;
};

export default path;
